<template lang="">
  <div class="resumeForm">
    <b-form v-if="show">
      <draggable v-model="skills" handle=".handle">
        <b-input-group class="mb-2" v-for="(skill, i) in skills" :key="i">
          <b-input-group-prepend is-text>
            <b-icon icon="arrows-expand" class="handle"></b-icon>
          </b-input-group-prepend>
          <b-form-input
            type="text"
            v-model="skill.name"
            placeholder="Skill name"
          ></b-form-input>
          <b-input-group-append is-text v-on:click="skills.splice(i, 1)">
            <b-icon icon="trash" aria-hidden="true" variant="danger"></b-icon>
          </b-input-group-append>
        </b-input-group>
      </draggable>

      <b-button v-on:click="addSkill" variant="secondary" class="mt-2">
        <b-icon icon="plus-square" aria-hidden="true"></b-icon>
        Add skill
      </b-button>

      <hr />
      <b-form-group class="text-center" id="buttons">
        <b-button
          center
          v-on:click="submit_form(false, $event)"
          id="bt_save"
          variant="outline-primary"
          class=""
          >Save</b-button
        >
        <b-button
          v-on:click="submit_form(true, $event)"
          id="bt_submit"
          variant="success"
          class="ml-5"
          >Next: Misc &#10132;</b-button
        >
      </b-form-group>
    </b-form>
  </div>
</template>
<script>
import draggable from "vuedraggable";
export default {
  data() {
    return {
      show: true,
      overlay: false,
      zIndex: 0,
    };
  },
  computed: {
    skills: {
      get() {
        return this.$store.state.resume.skills;
      },
      set(val) {
        console.log("set skills");
        let resume = this.$store.state.resume;
        resume.skills = val;
        for (let i = 0; i < resume.skills.length; i++) {
          resume.skills[i].sort = i;
        }
        this.$store.commit("updateResume", resume);
        this.show = false;
        this.$nextTick(() => {
          this.show = true;
        });
      },
    },
  },
  components: {
    draggable,
  },

  methods: {
    /**********************************************************/
    addSkill() {
      let skills = this.skills;
      skills.push({ name: "" });
      this.skills = skills;
    },
    /**********************************************************/
    submit_form(redirect, event) {
      event.preventDefault();
      this.$emit("save", redirect);
    },
  },
};
</script>
<style>
.handle {
  cursor: move;
}
</style>
