<template lang="">
  <div>
    <h3>Skills</h3>
    <form-skills @save="save" />
  </div>
</template>
<script>
import FromSkills from "../components/forms/FormSkills.vue";

export default {
  components: {
    "form-skills": FromSkills,
  },

  mounted() {
    // let resume = this.$store.state.resume;
    // resume.skills = this.skills;
    // this.$store.commit("updateResume", resume);
    this.$api.fetchResumeSkills();
  },
  methods: {
    save(redirect) {
      // let resume = this.$store.state.resume;
      // resume.skills = skills;
      // this.$store.commit("updateResume", resume);
      this.$api.updateResumeSkills(this.$store.state.resume.skills);
      this.$api.successToast();

      if (redirect) {
        this.$router.push("review");
      }
    },
  },
};
</script>
<style lang=""></style>
